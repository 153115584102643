<template>
  <Box>
  
    
    <ErrorRow :error="error" /> 
    <SuccessRow :message="message" /> 
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
    
    <TitleRow>{{ MC.Template.Title.value() }}</TitleRow>
    
    <Row>
      <Column>
        <Button v-on:click="newCheckList()">+ CheckList</Button>
        <Value> Value </Value>
      </Column>
    </Row>
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
    <SpacerRow v-else/>
  </Box>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  
import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: "checklist-detials-view",
  components: {
    Box, Row, Column,
    SpacerRow, TitleRow, 
    Button, Value,
    LoadingRow,
    ErrorRow, SuccessRow, SessionTimeoutDialog,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      
      isLoading: true,
      loadingMessage: null,
      showSessionTimeoutDialog: false,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    newCheckList: function() {
      this.$router.push({
        name: ConstUtils.ROUTES.CHECKLIST.ADD,
      });
    }
  },
}
</script>